/* FIXES
--------------------------------------------------------------------------------------------*/

[data-s-type="hero"] .ac_hero_slide_content_text_title * {
    font-size: 40px;
    text-align: center;
    text-shadow: 1px 1px $brand-darkest-gray;

}

#home-hero-1{
    max-height:460px;
}

.ac_hero_slide_inner {
    max-height:460px;
}

[data-s-type="hero"] .ac_hero_container {
    max-height:460px;
}

[data-s-id="home-services"] {
    padding:0px;
}

[data-s-id="home-toptasks"] {
    margin-top:-40px;

    @media only screen and (max-width: 800px ){
        padding-bottom: 30px;

        .ac_toptasks_item:last-of-type{
            width: 100%;
            max-width: 100%;
        }
    }
}

[data-m-type="menu-2"] a {
    color: $brand-darkest-gray;
}

.ac_menu-2_main_content a {
    font-size:20px;
    color: $brand-darkest-gray;
}

.ac_menu-2_main_content .button.v_transparent_body {
    font-weight: bold !important;
}

.ac_menu-2_bar_top {
    background-color: $brand-light-gray;
    font-weight: 600;
    a {
        color:$brand-tertiary-darker !important;
    }
}

.ac_menu-2_bar_top a{
    color: $brand-black;
}

.ac_menu-2_bar_bottom{
    background: $brand-primary;
    a{
        color: $brand-white;
        font-weight: bold;
    }
}

.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
    border-color: #ffffff transparent transparent;
}

[data-f-type="footer-1"] .ac_footer_primary {
    background-color: $brand-black;
}

body {
    color:$brand-black;
}

[data-f-type="footer-2"] {
    background-color: $brand-dark-gray;
}

[data-f-type="footer-2"] h2 {
    margin-bottom: -15px;
    margin-right:10px;
}

#home-services-5[data-s-type="services"] .ac_item_image-container:before{
    background: none;
    
}

#home-services-5[data-s-type="services"]{
    .ac_item_image-container {
        background: none;
        border: 1px solid #ffffff;
        border-radius: 0 0 0 50px;
    }

    .ac_item{ 
        margin: 0;
        padding: 0 7px 7px 0;

        @media only screen and (max-width: 800px){
            margin-bottom: 13px;
        }
    }

    
}

#home-services-5 .ac_item_image-container figure {
    display: none;
}


[data-s-type="hero"] .ac_hero_slide_content_inner{ 
    justify-content: flex-start;
    max-width: 65%;

    .ac_hero_slide_content_text_title *{
        text-align: left;
    }

}

@media only screen and (max-width: 800px){
    .ac_menu-2_sticky{
        display: block;
    }
    .ac_menu-2_logo_img {
        padding-left:25px;
        margin:10px;
    }

}

.ac_menu-2_sticky.is-stuck .ac_menu-2_logo_img {
    width:auto;
}

.ac_menu-2_logo_img {
    width: 450px;
    max-width: 450px;
    margin-left:-35px;
    margin-bottom: -10px;
}

.is-dropdown-submenu {
    background-color: $brand-primary;
}

[data-s-type="services"] .ac_item_content {
    background-color: $brand-primary;
    border-radius: 10px;;
}

[data-s-type="services"] .ac_item_content:hover {
    background-color: $brand-secondary;
    border-radius: 10px;;
}


#home-sectie-titel-3-3 .ac_section_divider[data-s-divider-type="spiked-small"] .ac_section_divider_top{
    top:-15px;
}


input[type="radio" i] {
    padding:0 !important;
    margin:0 !important;
}

.gform_wrapper ul.gfield_checkbox li, .gform_wrapper ul.gfield_radio li {
    padding:0 !important;
    margin:0 !important;
    margin-right:10px !important;
    display:inline;
}

.ac_content_1-1 {
    padding-left:10px;
}

[data-s-type="header-image"] .ac_content_container{
    width: 100%;
    max-width: initial;

     div{
         padding:0;
         margin: 0;
         width: 100%;
     }

     img{
         width: 100%;
         height: auto;
     }
}